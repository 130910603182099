import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
   initialize() {
      this.stickinessOvserver = new IntersectionObserver(
         ([el]) => {
            const isStuck = el.intersectionRatio < 1;

            el.target.classList.toggle("is-stuck", isStuck);

            const tooltips = Array.from(el.target.querySelectorAll(".tooltip"));

            if (isStuck) this.removeTooltips(tooltips);
            else this.restoreTooltips();
         },
         { threshold: [1] }
      );
      this.stickinessOvserver.observe(this.element);
   }

   disconnect() {
      this.stickinessOvserver.disconnect();
   }

   removeTooltips(tooltips) {
      this.removedTooltips = tooltips.map((tooltip) => {
         tooltip.remove();
         return tooltip;
      });
   }

   restoreTooltips() {
      if (!this.removedTooltips) return;

      const ths = Array.from(this.element.querySelectorAll("th"));

      let i = 0;

      for (const th of ths) {
         if(! this.removedTooltips[i]){
            i++;
            continue;
         };

         const anchor = th.querySelector("a")
         if(anchor.textContent.trim() === "Description") continue;

         anchor.appendChild(this.removedTooltips[i]);
         i++;

      }

      this.removedTooltips = null;
   }
}
